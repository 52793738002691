.test-actions-opened {
  z-index: 1;
  width: 13vh !important;
}

.swipeable-list-item__content--return {
  z-index: 2;
  position: relative;
}

.add-prayer-button {
  font-size: 6vh !important;
  stroke: #ffffff;
  stroke-width: 0.2vh;
}