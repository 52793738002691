.App {
  /*text-align: center;*/
  background-color: #ffffff;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  color: #565656;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.chip-grey {
  color: #ffffff !important;
  border: 1px solid #384347 !important;
  background: #384347 !important;
}

body {
  overscroll-behavior-y: contain;
}

.itemTopics{
  justify-content: center !important;
  text-decoration: none;
  background-color: #053d59;
  margin-bottom: 1.5vh;
  font-size: 17px;
  text-transform: capitalize;
  color: #ced9df;
  border-radius: 4vh;
  text-align: center;
}