body {
  margin: 0;
  padding: 0;
  font-family: 'SourceSansProRegular' !important;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

[class*="-root"], textarea {
  font-family: 'SourceSansProRegular' !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.fontLatoBlack {
  font-family: 'LatoBlack' !important;
}

.fontLatoBlack input, .fontLatoBlack fieldset, .fontLatoBlack select, .fontLatoBlack textarea, .fontLatoBlack fieldset{
  font-family: 'LatoBlack' !important;
}

.fontLatoRegular {
  font-family: 'LatoRegular' !important;
}

.fontLatoRegular input, .fontLatoRegular fieldset, .fontLatoRegular select, .fontLatoRegular textarea, .fontLatoRegular fieldset{
  font-family: 'LatoRegular' !important;
}

.fontLatoLight {
  font-family: 'LatoLight' !important;
}

.fontLatoLight input, .fontLatoLight fieldset, .fontLatoLight select, .fontLatoLight textarea, .fontLatoLight fieldset{
  font-family: 'LatoLight' !important;
}

.fontSourceSansProSemiBold {
  font-family: 'SourceSansProSemiBold' !important;
}

.fontSourceSansProSemiBold input, .fontSourceSansProSemiBold fieldset, .fontSourceSansProSemiBold select, .fontSourceSansProSemiBold textarea, .fontSourceSansProSemiBold fieldset{
  font-family: 'SourceSansProSemiBold' !important;
}

.fontSourceSansProRegular {
  font-family: 'SourceSansProRegular' !important;
}

.fontSourceSansProRegular input, .fontSourceSansProRegular fieldset, .fontSourceSansProRegular select, .fontSourceSansProRegular textarea, .fontSourceSansProRegular fieldset{
  font-family: 'SourceSansProRegular' !important;
}

.fontSourceSansProBold {
  font-family: 'SourceSansProBold' !important;
}

.fontSourceSansProBold input, .fontSourceSansProBold fieldset, .fontSourceSansProBold select, .fontSourceSansProBold textarea, .fontSourceSansProBold fieldset{
  font-family: 'SourceSansProRegular' !important;
}

button {
  background-color: #9a1418;
}

.carousel .control-dots{
  bottom: 4vh !important;
}

.carousel .control-dots .dot {
    width: 1.5vh !important;
    height: 1.5vh !important;
    opacity: 0.3 !important;
}

.carousel .control-dots .dot.selected {
  opacity: 1 !important;
  display: none !important;
}

.MuiTimeline-root li:not(:last-child) .MuiTimelineSeparator-root{
  margin-bottom: -3.9vh
}

.backgroundColorContent{
  background-color: #f7f4f2 !important;
}

@font-face {
  font-family: "LatoBlack";
  src: local("LatoBlack"),
    url("./fonts/lato/Lato-Black.ttf") format("truetype");
}
@font-face {
  font-family: "LatoBlackItalic";
  src: local("LatoBlackItalic"),
    url("./fonts/lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "LatoBold";
  src: local("LatoBold"),
    url("./fonts/lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "LatoBoldItalic";
  src: local("LatoBoldItalic"),
    url("./fonts/lato/Lato-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "LatoItalic";
  src: local("LatoItalic"),
    url("./fonts/lato/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "LatoLight";
  src: local("LatoLight"),
    url("./fonts/lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "LatoLightItalic";
  src: local("LatoLightItalic"),
    url("./fonts/lato/Lato-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "LatoRegular";
  src: local("LatoRegular"),
    url("./fonts/lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "LatoThin";
  src: local("LatoThin"),
    url("./fonts/lato/Lato-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "LatoThinItalic";
  src: local("LatoThinItalic"),
    url("./fonts/lato/Lato-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProBlack";
  src: local("SourceSansProBlack"),
    url("./fonts/source-sans-pro/SourceSansPro-Black.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProBlackItalic";
  src: local("SourceSansProBlackItalic"),
    url("./fonts/source-sans-pro/SourceSansPro-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProBold";
  src: local("SourceSansProBold"),
    url("./fonts/source-sans-pro/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProBoldItalic";
  src: local("SourceSansProBoldItalic"),
    url("./fonts/source-sans-pro/SourceSansPro-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProExtraLight";
  src: local("SourceSansProExtraLight"),
    url("./fonts/source-sans-pro/SourceSansPro-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProExtraLightItalic";
  src: local("SourceSansProExtraLightItalic"),
    url("./fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProItalic";
  src: local("SourceSansProItalic"),
    url("./fonts/source-sans-pro/SourceSansPro-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProLight";
  src: local("SourceSansProLight"),
    url("./fonts/source-sans-pro/SourceSansPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProLightItalic";
  src: local("SourceSansProLightItalic"),
    url("./fonts/source-sans-pro/SourceSansPro-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProRegular";
  src: local("SourceSansProRegular"),
    url("./fonts/source-sans-pro/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProSemiBold";
  src: local("SourceSansProSemiBold"),
    url("./fonts/source-sans-pro/SourceSansPro-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProSemiBoldItalic";
  src: local("SourceSansProSemiBoldItalic"),
    url("./fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansProBold";
  src: local("SourceSansProBold"),
    url("./fonts/source-sans-pro/SourceSansPro-Bold.ttf") format("truetype");
}
