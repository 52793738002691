.stick-to-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.full-drawer {
  height: 100% !important;
}

.drawer {
  width: 80vw;
  height: 100vh;
  border: none;
}

.app-logo-container {
  height: 15vh;
  min-width: 100%;
  background-color: #ab1f1a;
  background-repeat: repeat-x;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}

.logo-container {
  max-width: 50%;
}

.logo {
  width: 100%;
}

.drawerNotifications {
  margin: auto;
  max-width: 450px;
}
.drawerMenu {
  margin: auto;
  max-width: 450px;
}

.drawerContentNotifications {
  position: absolute !important;
  right: -25px !important;
  /* width: 280px; */
  transition: none !important;
}

.drawerContentMenu {
  position: absolute !important;
  right: -25px !important;
  width: 270px;
  transition: none !important;
}